module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sveahus","short_name":"Sveahus","start_url":"/","lang":"en","theme_color":"#fddc75","background_color":"#ffffff","description":"It started with a vision to satisfy the need for housing in the Scandinavian market, on a large scale and of good quality.","display":"standalone","icon":"src/images/icon.png","orientation":"any","localize":[{"start_url":"/sv","lang":"sv","name":"Sveahus","short_name":"Sveahus","description":"Sveahus vision är att möta den skandinaviska marknadens behov av bostäder, av hög kvalitet och för produktion på en större skala."}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-131660027-1","anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
