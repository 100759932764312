import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/core'

export { styled, css, keyframes }

export const theme = {
  /* Breakpoints picked from Chrome dev tools. */
  breakpoints: [0, 425, 768, 1024, 1440, 2560],
  mq: {
    mobile: `(min-width: 0)`,
    mobileL: `(min-width: 425px)`,
    tablet: `(min-width: 768px)`,
    laptop: `(min-width: 1024px)`,
    desktop: `(min-width: 1440px)`,
    fullhd: `(min-width: 2560px)`
  },
  colors: {
    blue: `#21438e`,
    green: `#23d160`,
    red: `#ff3860`,
    yellow: `#fddc75`,
    white: `#ffffff`,
    grayLight: `#9b9b9b`,
    gray: `#4a4a4a`,
    black: `#222222`,
    light: `#f5f5f5`,
    dark: `#363636`,
    text: `#222222`,
    facebook: `#3b5998`,
    linkedin: `#007bb5`,
    twitter: `#55acee`,
    google: `#dd4b39`,
    youtube: `#ff0000`,
    instagram: `#e95950`,
    pinterest: `#cb2027`,
    primary: `#fddc75`,
    info: `#21438e`,
    success: `#23d160`,
    warning: `#fddc75`,
    danger: `#ff3860`
  },
  fonts: {
    sans: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Open Sans',
      'Helvetica Neue',
      'sans-serif'
    ].join(),
    mono: [
      'Space Mono',
      'SFMono-Regular',
      'Menlo',
      'Monaco',
      'Consolas',
      'Liberation Mono',
      'Courier New',
      'monospace'
    ].join()
  },
  fontSize: [12, 14, 16, 20, 24, 32, 48, 64],
  fontWeight: {
    bold: 700,
    medium: 500,
    normal: 400,
    light: 300
  }
}
