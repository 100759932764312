/* eslint-disable react/prop-types */
import React, { Component, createContext } from 'react'
import { navigate } from 'gatsby'

const defaultLocale = 'en'
const whitelist = ['en', 'sv']

const defaultState = {
  locale: defaultLocale,
  changeLocale: () => {}
}

const { Provider, Consumer } = createContext(defaultState)

const parseLocale = locale => {
  return locale ? locale.split('-')[0] : null
}

const detectUserLanguage = () => {
  const userLang =
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language ||
    window.navigator.userLanguage

  return userLang && whitelist.includes(parseLocale(userLang))
    ? parseLocale(userLang)
    : null
}

class LocaleProvider extends Component {
  constructor(props) {
    super(props)

    this.state = { ...defaultState }
  }

  componentDidMount() {
    // Getting locale value from localStorage!
    const lsLocale = JSON.parse(localStorage.getItem('sveahus-locale'))
    if (lsLocale) {
      this.setState({ locale: lsLocale })
    } else {
      // Try detect user locale from browser.
      const userLocale = detectUserLanguage()
      if (userLocale) this.setState({ locale: userLocale })
    }
  }

  changeLocale = locale => {
    localStorage.setItem('sveahus-locale', JSON.stringify(locale))
    this.setState({ locale })
    navigate(this.findLink(locale))
  }

  findLink(locale) {
    const { path, pageContext, isCreatedByStatefulCreatePages } = this.props
    if (!isCreatedByStatefulCreatePages || path === '/') {
      const { slug, ancestor } = pageContext.slugs.find(
        s => s.node_locale === locale
      )

      const prefix =
        locale === defaultLocale || whitelist.includes(slug) ? null : locale
      let to = [prefix, ancestor ? ancestor.slug : null, slug]
        .filter(Boolean)
        .join('/')

      if (to === defaultLocale) to = '/'
      return to
    }
    return '/'
  }

  render() {
    const { children } = this.props
    const { locale } = this.state

    return (
      <Provider value={{ locale, changeLocale: this.changeLocale }}>
        {children}
      </Provider>
    )
  }
}

export { Consumer as default, LocaleProvider }
