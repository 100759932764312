import React from 'react'
import PropTypes from 'prop-types'
import { Global } from '@emotion/core'
import { IntlProvider } from 'react-intl'
import { globalStyles, Wrapper } from './styles'

const Layout = ({ children }) => {
  // Patch..
  if (
    children.props.location.pathname === '/offline-plugin-app-shell-fallback/'
  )
    return null

  const locale =
    children.props.location.pathname.split('/')[1] === 'sv' ? 'sv' : 'en'

  return (
    <IntlProvider locale={locale} timeZone="Europe/Stockholm">
      <Wrapper>
        <Global styles={globalStyles} />
        {children}
      </Wrapper>
    </IntlProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
