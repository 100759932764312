/* eslint-disable react/prop-types, no-console */
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { ContentProvider } from './src/context/content'
import { LocaleProvider } from './src/context/locale'
import Layout from './src/components/layout'

export const wrapPageElement = ({ element, props }) => {
  return (
    <CookiesProvider>
      <ContentProvider>
        <LocaleProvider {...props}>
          <Layout {...props}>{element}</Layout>
        </LocaleProvider>
      </ContentProvider>
    </CookiesProvider>
  )
}

export const onClientEntry = () => {
  // Credits.
  console.log(`Code by https://github.com/futhr (っ◕‿◕)っ`)
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === 'undefined') {
    import('intersection-observer')
  }
}

// Service worker.
export const onServiceWorkerUpdateReady = () => window.location.reload()
