/* eslint-disable react/no-unused-state, react/prop-types */
import React, { Component, createContext } from 'react'

const defaultState = {
  state: {
    menuOpen: false,
    isSidebarOpen: false
  },
  set: () => {}
}

const { Provider, Consumer } = createContext(defaultState)

class ContentProvider extends Component {
  constructor(props) {
    super(props)

    this.setData = this.setData.bind(this)
    this.state = {
      ...defaultState,
      set: this.setData
    }
  }

  setData(newData) {
    this.setState(state => ({
      state: {
        ...state.data,
        ...newData
      }
    }))
  }

  render() {
    const { children } = this.props
    return <Provider value={this.state}>{children}</Provider>
  }
}

export { Consumer as default, ContentProvider }
