import emotionNormalize from 'emotion-normalize'
import { rgba } from 'polished'
import { theme, styled, css } from '../../shared/theme'

export const globalStyles = css`
  ${emotionNormalize}

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  html {
    font-family: sans-serif;
    overflow: hidden;
  }

  html,
  body {
    height: 100%;
  }

  body {
    position: relative;
    overflow-y: scroll;
    backface-visibility: hidden;
    font-family: ${theme.fonts.sans};
    font-size: ${theme.fontSize[2]};
    font-weight: ${theme.fontWeight.medium};
    line-height: 1.5;
    color: ${theme.colors.black};
    background-color: ${theme.colors.white};
  }

  *:focus {
    outline: 0;
  }
`

export const Wrapper = styled.main`
  position: relative;
  top: 0;
  left: 0;
  z-index: 99;
  height: auto;

  &.pushed {
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: ${rgba(theme.colors.black, 0.6)};
      z-index: 100;
    }
  }
`
